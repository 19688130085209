///////////////////////////////////////////////////////////////////////////////////MODULES
import { MainImage, Row, Col } from '@ibiliaze/reactstrap';
import { useNavigate } from 'react-router-dom';
import { FadeIn, TransformYDown } from '@ibiliaze/react-intersect';
import { Section } from '@ibiliaze/react-base';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
import { BiHeart, BiBook } from '../../utils/icons';
//////////////////////////////////////////////////////////////////////////////////////////

const SecondaryAbout = () => {
  // Navigate
  const navigate = useNavigate();

  // JSX
  return (
    <>
      <TransformYDown>
        <div className='p-t-xl p-b-xl'>
          <MainImage
            imgPath={constants.secondaryPic}
            title={constants.secondaryPicTitle}
            slogan={constants.secondaryPicText}
            buttonText={constants.secondaryPicButton}
            onButtonClick={_ => navigate('/services')}
            imgBrightness='.35'
            imgHeight='600px'
            imgMobileHeight='400px'
          />
        </div>
      </TransformYDown>
      <Section className='custom-page p-t-xl p-b-xl'>
        <FadeIn>
          <h1 className='t-align-c'>
            You're in <span className='text-gradient'>Safe Hands</span>
          </h1>
        </FadeIn>
        <Row>
          <Col md={6}>
            <TransformYDown>
              <h3>
                Warranty Included{' '}
                <span>
                  <BiBook />
                </span>
              </h3>
              <p>
                We take pride in the quality of our work and the satisfaction of our clients. That's why we offer a
                6-month warranty on all of our construction projects. You can trust that the work we do for you will be
                completed to the highest standards and will meet your expectations. If any issues arise within 6 months
                of completion, we will work to quickly resolve them and ensure your complete satisfaction.
              </p>
            </TransformYDown>
          </Col>
          <Col md={6}>
            <TransformYDown>
              <h3>
                Insurance{' '}
                <span>
                  <BiHeart />
                </span>
              </h3>
              <p>
                The safety and security of our clients and workers are our top priorities. That's why we take all
                necessary steps to ensure we are fully insured against any potential accidents or damages. We have
                comprehensive liability insurance as well as workers' compensation insurance to protect our clients and
                our team in case of any unforeseen events. We are fully committed to providing you with the highest
                standards of safety, quality and customer service.
              </p>
            </TransformYDown>
          </Col>
        </Row>
      </Section>
      <br />
    </>
  );
};

export default SecondaryAbout;
