///////////////////////////////////////////////////////////////////////////////////MODULES
import { Banner as CustomBanner } from '@ibiliaze/reactstrap';
import { Section } from '@ibiliaze/react-base';
import { ScaleIn } from '@ibiliaze/react-intersect';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const Banner = () =>
  !!constants.banner && (
    <ScaleIn>
      <Section className='custom-page p-t-l p-b-l'>
        <CustomBanner {...constants.banner} />
      </Section>
    </ScaleIn>
  );

export default Banner;
