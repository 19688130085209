/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from '../actions/types/types';
////////////////////////////////////////////////////////////////////////AUTH DEFAULT STATE
const adminDefaultState = {
  isAuthenticated: false,
  admin: null,
};
//////////////////////////////////////////////////////////////////////////////AUTH REDUCER
const admin = (state = adminDefaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    // Register
    case types.REGISTER_ADMIN_SUCCESS:
      return { ...state, admin: null, isAuthenticated: false };

    // Login
    case types.LOGIN_ADMIN_SUCCESS:
      return { ...state, ...payload, isAuthenticated: true };

    // GET request
    case types.GET_ADMIN_SUCCESS:
      return { ...state, ...payload, isAuthenticated: true };

    // PUT request
    case types.PUT_ADMIN_SUCCESS:
      return { ...state, admin: payload.admin };
    case types.PUT_ADMIN_FAILURE:
      return { ...state };

    // Auth failure & logout & DELETE request
    case types.LOGIN_ADMIN_FAILURE:
    case types.REGISTER_ADMIN_FAILURE:
    case types.GET_ADMIN_FAILURE:
    case types.LOGOUT_ADMIN_SUCCESS:
    case types.LOGOUT_ADMIN_FAILURE:
      return { ...state, admin: null, isAuthenticated: false };

    case types.DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        admin: null,
        isAuthenticated: false,
      };

    case types.DELETE_ADMIN_FAILURE:
      return { ...state };

    default:
      return state;
  }
};

export default admin;
