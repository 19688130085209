///////////////////////////////////////////////////////////////////////////////////MODULES
import { useNavigate } from 'react-router-dom';
import { Col, Row } from '@ibiliaze/reactstrap';
import { Section } from '@ibiliaze/react-base';
import { FadeIn } from '@ibiliaze/react-intersect';
import { Multirow } from '@ibiliaze/react-multirow';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { Domestic, Commercial, Quote } from '../../utils/icons';
//////////////////////////////////////////////////////////////////////////////////////////

const About = () => {
  // Navigate
  const navigate = useNavigate();

  // JSX
  return (
    <Section id='about-section' className='custom-page p-t-xl'>
      <FadeIn>
        <h1 className='t-align-c'>What We Do</h1>
      </FadeIn>

      <Row>
        <Col md={4}>
          <Multirow
            onClick={_ => navigate('/services')}
            img='/img/commercial.jpg'
            alt='commercial'
            header='Commercial Work'
            icon={<Commercial size='l' />}
            body='We have invested in all aspects to ensure that we offer a market leading approach to design and build.'
          />
        </Col>

        <Col md={4}>
          <Multirow
            onClick={_ => navigate('/services')}
            img='/img/domestic.jpg'
            alt='domestic'
            header='Domestic Work'
            icon={<Domestic size='l' />}
            body='Highly professional company with a passion for innovative designs and quality construction.'
          />
        </Col>

        <Col md={4}>
          <Multirow
            onClick={_ => navigate('/services')}
            img='/img/quotes.jpg'
            alt='quotes'
            header='Free Estimates'
            icon={<Quote size='l' />}
            body='Get your free quote today. Our team are always ready to help. Talk to us or call us directly for an
            immediate quote.'
          />
        </Col>
      </Row>
    </Section>
  );
};

export default About;
