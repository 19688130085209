///////////////////////////////////////////////////////////////////////////////////MODULES
import { Api } from '@ibiliaze/react-base';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////TYPES
import * as types from './types/types';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from '../utils/resolveEnv';
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

export const getAdminSuccess = payload => ({ type: types.GET_ADMIN_SUCCESS, payload });
export const getAdminFailure = errorMessage => ({ type: types.GET_ADMIN_FAILURE, errorMessage });
export const getAdmin = endpoint => async dispatch => {
  try {
    const res = await api.get(!endpoint ? `${resolveHost()}/api/admin` : `${resolveHost()}/api/admin${endpoint}`);

    dispatch(getAdminSuccess(res.data));
  } catch (e) {
    dispatch(getAdminFailure(e.message));
  }
};

// Register
export const registerSuccess = payload => ({ type: types.REGISTER_ADMIN_SUCCESS, payload });
export const registerFailure = errorMessage => ({ type: types.REGISTER_ADMIN_FAILURE, errorMessage });
export const register = data => async dispatch => {
  try {
    dispatch(setAlert('Creating account...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/admin`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.REGISTER_ADMIN_SUCCESS));
    dispatch(registerSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.REGISTER_ADMIN_FAILURE));
    dispatch(registerFailure(e.message));
  }
};

// Login
export const loginSuccess = payload => ({ type: types.LOGIN_ADMIN_SUCCESS, payload });
export const loginFailure = errorMessage => ({ type: types.LOGIN_ADMIN_FAILURE, errorMessage });
export const login = data => async dispatch => {
  try {
    dispatch(setAlert('Signing in...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/admin/token`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.LOGIN_ADMIN_SUCCESS));
    dispatch(loginSuccess(res.data));
    dispatch(getAdmin());
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.LOGIN_ADMIN_FAILURE));
    dispatch(loginFailure(e.message));
  }
};

// Logout
export const logoutSuccess = payload => ({ type: types.LOGOUT_ADMIN_SUCCESS, payload });
export const logoutFailure = errorMessage => ({ type: types.LOGOUT_ADMIN_FAILURE, errorMessage });
export const logout = () => async dispatch => {
  try {
    dispatch(setAlert('Signing out...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/admin/logout`);

    dispatch(setAlert(res.data.message, false, res.status, types.LOGOUT_ADMIN_SUCCESS));
    dispatch(logoutSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.LOGOUT_ADMIN_FAILURE));
    dispatch(logoutFailure(e.message));
  }
};

// PUT /api/admin
export const putAdminSuccess = payload => ({ type: types.PUT_ADMIN_SUCCESS, payload });
export const putAdminFailure = errorMessage => ({ type: types.PUT_ADMIN_FAILURE, errorMessage });
export const putAdmin = data => async dispatch => {
  try {
    dispatch(setAlert('Updating the account...', false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/admin`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_ADMIN_SUCCESS));
    dispatch(putAdminSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.PUT_ADMIN_FAILURE));
    dispatch(putAdminFailure(e.message));
  }
};

// PUT /api/admin/password
export const putAdminPassword = data => async dispatch => {
  try {
    dispatch(setAlert('Updating the password...', false, null, null, true));
    const res = await api.put(`${resolveHost()}/api/admin/password`, data);

    dispatch(setAlert(res.data.message, false, res.status, types.PUT_ADMIN_SUCCESS));
    dispatch(putAdminSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.PUT_ADMIN_FAILURE));
    dispatch(putAdminFailure(e.message));
  }
};

// DELETE /api/admin
export const deleteAdminSuccess = payload => ({ type: types.DELETE_ADMIN_SUCCESS, payload });
export const deleteAdminFailure = errorMessage => ({ type: types.DELETE_ADMIN_FAILURE, errorMessage });
export const deleteAdmin = () => async dispatch => {
  try {
    dispatch(setAlert('Deleting the account...', false, null, null, true));
    const res = await api.delete(`${resolveHost()}/api/admin`);

    dispatch(setAlert(res.data.message, false, res.status, types.DELETE_ADMIN_SUCCESS));
    dispatch(deleteAdminSuccess(res.data));
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, types.DELETE_ADMIN_FAILURE));
    dispatch(deleteAdminFailure(e.message));
  }
};
