///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { msToHm } from '@ibiliaze/time';
import { CustomNav, Offcanvas, OffcanvasHeader, OffcanvasBody, Table } from '@ibiliaze/reactstrap';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { logout } from '../../actions/admin';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from '../../utils/constants';
import scroll from '../../utils/scroll';
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Wishlist from '../Wishlist/Wishlist';
//////////////////////////////////////////////////////////////////////////////////////////

const Header = ({ wishlist, admin, logout }) => {
  // State
  const [wishlistModal, setWishlistModal] = useState(false);
  const [offcanvas, setOffcanvas] = useState(false);

  // Toggle functions
  const toggleWishlistModal = () => setWishlistModal(!wishlistModal);
  const toggleOffcanvas = () => setOffcanvas(!offcanvas);

  // Navigate
  const navigate = useNavigate();

  // onClick functions
  const onWishlistClick = () => toggleWishlistModal();

  // JSX
  return (
    <>
      <CustomNav
        dark={c.header.dark}
        blur={c.header.blur}
        navbarBrand={c.name}
        navbarBrandHref='#'
        navbarBrandOnClick={_ => navigate('/')}
        fadeOnPaths={c.header.fadeOnPaths}
        navlinks={[
          {
            title: 'About',
            href: '#',
            onClick: _ => {
              navigate('/');
              scroll(document.getElementById('about-section')?.offsetTop);
            },
            hide: false,
          },
          {
            title: 'Services',
            href: '#',
            onClick: _ => navigate('/services'),
            hide: false,
          },
          {
            title: 'Contact',
            href: '#',
            onClick: _ => {
              navigate('/');
              window.scrollTo(0, document.body.scrollHeight);
            },
            hide: false,
          },
        ]}
        navlinksRight={[
          // Guest links
          {
            title: `Wishlist ♥ ${wishlist.items.length}`,
            href: '#',
            onClick: _ => onWishlistClick(),
            hide: false,
            badge: true,
            badgeColour: 'danger',
          },
          {
            title: 'Visit us',
            href: '#',
            onClick: toggleOffcanvas,
            hide: false,
          },
          {
            title: 'Login',
            href: '#',
            onClick: _ => {
              navigate('/auth');
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 1);
            },
            hide: admin?.isAuthenticated,
          },

          // Auth links
          {
            title: admin && admin?.admin ? `${admin?.admin?.name}@${c.name}` : '',
            href: '#',
            hide: !admin?.isAuthenticated,
            onClick: _ => {
              if (admin?.isAuthenticated) {
                navigate('/');
              }
              setTimeout(() => {
                window.scrollTo(0, 0);
              }, 1);
            },
          },
          {
            title: 'Logout',
            href: '#',
            hide: !admin?.isAuthenticated,
            onClick: _ => {
              if (admin?.isAuthenticated) {
                logout();
              }
            },
          },
        ]}
      />
      <Offcanvas direction='end' fade scrollable isOpen={offcanvas} toggle={toggleOffcanvas}>
        <OffcanvasHeader toggle={toggleOffcanvas}>Working Hours</OffcanvasHeader>
        <OffcanvasBody>
          <Table borderless>
            <tbody>
              {Object.keys(c.workingHrs.days).map((dow, i) => {
                const wh = c.workingHrs.days[dow];
                return (
                  <tr key={i}>
                    <th scope='row'>{dow}</th>
                    <td>{wh.start === wh.end ? 'Closed' : msToHm(wh.start)}</td>
                    <td>{wh.start === wh.end ? 'Closed' : msToHm(wh.end)}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </OffcanvasBody>
      </Offcanvas>
      <Wishlist modal={wishlistModal} toggle={toggleWishlistModal} />
    </>
  );
};

const mapStateToProps = state => ({ wishlist: state.wishlist, admin: state.admin });

export default connect(mapStateToProps, { logout })(Header);
