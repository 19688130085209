///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState } from 'react';
import { connect } from 'react-redux';
import {
  CustomInput,
  CustomButton,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  CloseButton,
  FormText,
} from '@ibiliaze/reactstrap';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { deleteItem, resetWishlist } from '../../actions/wishlist';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
//////////////////////////////////////////////////////////////////////////////////////////

const Wishlist = ({ toggle, modal, wishlist, deleteItem }) => {
  // State
  const [inputs, setInputs] = useState({ clientName: '', email: '', phone: '' });

  // onChange functions
  const onInputsChange = e => setInputs({ ...inputs, [e.target.name]: e.target.value });

  // onClick functions
  const onRemoveClick = service => deleteItem(service.id);

  const onSubmit = e => {
    e.preventDefault();
    window.open(
      `https://wa.me/${constants.phone2}?text=${`Hey ${
        constants.chatPerson
      },%0AThe following is my enquiry:%0A${wishlist.items
        .map(item => ` • ${item.serviceName}`)
        .join('%0A')}%0A%0AThese are my details:%0A${inputs.clientName}%0A${inputs.email}%0A${inputs.phone}%0A`}`
    );
  };

  // JSX
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <Form onSubmit={onSubmit}>
        <ModalHeader toggle={toggle}>Wishlist</ModalHeader>
        <ModalBody>
          <div className='xs-text'>
            <Table borderless>
              <thead>
                <tr>
                  <th>Services</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {wishlist.items.map((service, i) => {
                  return (
                    <tr key={i}>
                      <td>{service.serviceName}</td>
                      <td>
                        <CloseButton onClick={_ => onRemoveClick(service)} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
          <FormGroup>
            <CustomInput
              required
              name='clientName'
              placeholder='Name'
              value={inputs.clientName}
              onChange={onInputsChange}
            />
          </FormGroup>
          <FormGroup>
            <CustomInput required name='email' placeholder='Email' value={inputs.email} onChange={onInputsChange} />
          </FormGroup>
          <FormGroup>
            <CustomInput
              required
              name='phone'
              type='tel'
              placeholder='Phone'
              value={inputs.phone}
              onChange={onInputsChange}
            />
          </FormGroup>
          {wishlist.items.length === 0 && <FormText>Add a service to your wishlist</FormText>}
        </ModalBody>
        <ModalFooter>
          <CustomButton type='submit' disabled={wishlist.items.length === 0 ? true : false} color='primary'>
            Get a Free Quote
          </CustomButton>
          <CustomButton onClick={_ => toggle()}>Cancel</CustomButton>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const mapStateToProps = state => ({ wishlist: state.wishlist });

export default connect(mapStateToProps, { deleteItem, resetWishlist })(Wishlist);
