///////////////////////////////////////////////////////////////////////////////////MODULES
import { Api } from '@ibiliaze/react-base';
import errorParser from '@ibiliaze/http-error-parser';
/////////////////////////////////////////////////////////////////////////////////////TYPES
import { CONFIRM_EMAIL_SUCCESS, CONFIRM_EMAIL_FAILURE, PUSH_EMAIL_SUCCESS, PUSH_EMAIL_FAILURE } from './types/types';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from './alert';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from '../utils/resolveEnv';
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

// POST /api/email
export const confirmEmailSuccess = () => ({ type: CONFIRM_EMAIL_SUCCESS });
export const confirmEmailFailure = errorMessage => ({ type: CONFIRM_EMAIL_FAILURE, errorMessage });
export const confirmEmail = data => async dispatch => {
  try {
    dispatch(setAlert('Sending confirmation email...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/email`, data);

    dispatch(setAlert(res.data.message, false, res.status, CONFIRM_EMAIL_SUCCESS));
    dispatch(confirmEmailSuccess());
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, CONFIRM_EMAIL_FAILURE));
    dispatch(confirmEmailFailure(e.message));
  }
};

// POST /api/email/push
export const pushEmailSuccess = () => ({ type: PUSH_EMAIL_SUCCESS });
export const pushEmailFailure = errorMessage => ({ type: PUSH_EMAIL_FAILURE, errorMessage });
export const pushEmail = data => async dispatch => {
  try {
    dispatch(setAlert('Sending email...', false, null, null, true));
    const res = await api.post(`${resolveHost()}/api/email/push`, data);

    dispatch(setAlert(res.data.message, false, res.status, PUSH_EMAIL_SUCCESS));
    dispatch(pushEmailSuccess());
  } catch (e) {
    dispatch(setAlert(errorParser(e), true, e.response ? e.response.status : 500, PUSH_EMAIL_FAILURE));
    dispatch(pushEmailFailure(e.message));
  }
};
