///////////////////////////////////////////////////////////////////////////////////MODULES
import { connect } from 'react-redux';
import { CustomCard, MiniNav, Row, Col, MainImage } from '@ibiliaze/reactstrap';
import { Section } from '@ibiliaze/react-base';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { postItem, deleteItem } from '../../actions/wishlist';
/////////////////////////////////////////////////////////////////////////////////////UTILS
import constants from '../../utils/constants';
import scroll from '../../utils/scroll';
//////////////////////////////////////////////////////////////////////////////////////////

const ServicesPage = ({ postItem, deleteItem }) => {
  // onClick functions
  const onAddClick = service => {
    postItem({
      serviceName: service.serviceName,
      price: service.price,
      id: service.id,
      quantity: 1,
    });
  };

  const onRemoveClick = service => deleteItem(service.id);

  // JSX
  return (
    <>
      <MainImage
        imgPath={constants.mainPic}
        title='Services'
        imgBrightness='.6'
        imgHeight='200px'
        imgMobileHeight='200px'
        infoTop='120px'
      />
      <MiniNav
        navlinks={constants.services.serviceGroups.map((serviceGroup, i) => ({
          title: serviceGroup.serviceGroupName,
          href: '#',
          onClick: _ => {
            scroll(document.getElementById(`services-${serviceGroup.serviceGroupName}-section`)?.offsetTop);
          },
        }))}
      />

      <Section className='custom-page p-t-l'>
        {constants.services.serviceGroups.map((serviceGroup, i) => (
          <section key={i} className='section' id={`services-${serviceGroup.serviceGroupName}-section`}>
            <br />
            <h4 className='t-align-c'>{serviceGroup.serviceGroupName}</h4>
            <hr />
            <Row>
              {serviceGroup.serviceItems.map((service, i) => (
                <Col key={i} md={3}>
                  <CustomCard
                    imgSrc={service.imageSrc}
                    titleTag='h6'
                    title={service.serviceName}
                    // text={service.description}
                    hoverTitle='Add to Wishlist'
                    clickedTitle={'Added - ' + service.serviceName}
                    onCardClickHandler={onAddClick}
                    onCardSecondClickHandler={onRemoveClick}
                    args={service}
                  />
                </Col>
              ))}
            </Row>
          </section>
        ))}
      </Section>
    </>
  );
};

export default connect(null, { postItem, deleteItem })(ServicesPage);
