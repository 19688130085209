import {
  BiHomeSmile as HomeSmile,
  BiBuildings as Buildings,
  BiPencil as Pencil,
  BiHeart as Heart,
  BiBook as Book,
} from 'react-icons/bi';

export const BiHomeSmile = props => <HomeSmile {...props} />;
export const BiBuildings = props => <Buildings {...props} />;
export const BiPencil = props => <Pencil {...props} />;
export const BiHeart = props => <Heart {...props} />;
export const BiBook = props => <Book {...props} />;

const sizeMap = {
  xxs: '15px',
  xs: '20px',
  s: '25px',
  m: '30px',
  l: '60px',
  xl: '77px',
  xxl: '94px',
};

// Header
export const Language = ({ size = 'xl' }) => (
  <img src='icons/language.png' alt='language' style={{ height: sizeMap[size] }} />
);

// Home Page
export const Commercial = ({ size = 'xl' }) => (
  <img src='icons/commercial.png' alt='check' style={{ height: sizeMap[size] }} />
);
export const Domestic = ({ size = 'xl' }) => (
  <img src='icons/domestic.png' alt='zoom' style={{ height: sizeMap[size] }} />
);
export const Quote = ({ size = 'xl' }) => <img src='icons/quote.png' alt='1st' style={{ height: sizeMap[size] }} />;
