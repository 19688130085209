///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Form,
  FormGroup,
  Label,
  CustomButton,
  UncontrolledTooltip,
  CustomInput,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormText,
} from '@ibiliaze/reactstrap';
import { FullPage, Section, ImagePageContent, ImagePageText, ImagePageButton } from '@ibiliaze/react-base';
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { login, register, getAdmin, putAdminPassword } from '../../actions/admin';
import { setAlert } from '../../actions/alert';
import { confirmEmail } from '../../actions/email';
//////////////////////////////////////////////////////////////////////////////////////////

const AuthPage = ({
  // Admin
  isAuthenticated,
  login,
  register,
  getAdmin,
  putAdminPassword,
  // Misc
  confirmEmail,
  setAlert,
}) => {
  // State
  const [authType, setAuthType] = useState('Login');
  const [modal, setModal] = useState(false);
  const [username, setUsername] = useState('');
  const [inputs, setInputs] = useState({
    name: '',
    email: '',
    tel: '',
    password: '',
    password2: '',
    code: '',
    regKey: '',
  });

  // Helper functions
  const auth = async () => {
    try {
      localStorage.setItem('username', username);
      const req = authType === 'Register' ? register : authType === 'Reset Password' ? putAdminPassword : login;
      await req({ ...inputs, status: 'Not verified' });
    } catch (e) {
      console.error(e);
    }
  };

  // Toggle functions
  const toggle = _ => setModal(!modal);

  // History
  const navigate = useNavigate();

  // onChange functions
  const onInputsChange = e => setInputs(c => ({ ...c, [e.target.name]: e.target.value }));
  const onUsernameChange = e => setUsername(e.target.value);

  // onClick functions
  const onRegisterClick = async _ => await auth();

  // Lifecycle hooks
  useEffect(() => {
    // If authentication is succesful redirect to the home page and set the current object states
    const req = async _ => {
      try {
        if (isAuthenticated) {
          navigate('/');
          await getAdmin();
        }
      } catch (e) {
        console.error(e);
      }
    };
    req();
    // eslint-disable-next-line
  }, [isAuthenticated]);

  // onSubmit function
  const onSubmit = async e => {
    e.preventDefault();

    try {
      if (authType !== 'Login' && inputs.password !== inputs.password2) {
        setAlert('Passwords do not match', true, null, null);
      } else if (authType !== 'Login') {
        await confirmEmail({ email: inputs.email?.toLowerCase() });
        toggle();
      } else {
        await auth();
      }
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <FullPage>
      <img alt='engineer' src='/img/page/auth-page.jpg' className='page-img' />
      <Section className='below-header custom-page p-t-m'>
        <ImagePageContent>
          <h1 className='t-align-c no-m'>
            <ImagePageText>{authType}</ImagePageText>
          </h1>
          <br />
          <Form onSubmit={onSubmit}>
            {authType === 'Register' && (
              <FormGroup floating>
                <CustomInput
                  required
                  placeholder='Name *'
                  type='text'
                  name='name'
                  value={inputs.name}
                  onChange={onInputsChange}
                />
                <Label>Name *</Label>
              </FormGroup>
            )}
            <Row>
              <Col md={authType === 'Register' ? 6 : 12}>
                <FormGroup floating>
                  <CustomInput
                    required
                    placeholder='Email *'
                    type='email'
                    name='email'
                    value={inputs.email}
                    onChange={onInputsChange}
                  />
                  <Label>Email *</Label>
                </FormGroup>
              </Col>
              <Col md={6}>
                {authType === 'Register' && (
                  <FormGroup floating>
                    <CustomInput
                      required
                      placeholder='Phone *'
                      type='number'
                      name='tel'
                      value={inputs.tel}
                      onChange={onInputsChange}
                    />
                    <Label>Phone *</Label>
                  </FormGroup>
                )}
              </Col>
            </Row>

            {authType === 'Register' && (
              <FormGroup floating>
                <CustomInput
                  required
                  placeholder='Admin Key *'
                  type='text'
                  name='regKey'
                  value={inputs.regKey}
                  onChange={onInputsChange}
                />
                <Label>Admin Key *</Label>
              </FormGroup>
            )}

            {authType === 'Login' && (
              <FormGroup floating>
                <CustomInput required placeholder='Username *' value={username} onChange={onUsernameChange} />
                <Label>Username *</Label>
              </FormGroup>
            )}

            {authType === 'Register' && <br />}
            <FormGroup floating>
              <CustomInput
                required
                placeholder='Password *'
                type='password'
                name='password'
                value={inputs.password}
                onChange={onInputsChange}
              />
              <Label>Password *</Label>
            </FormGroup>
            {authType !== 'Login' && (
              <FormGroup floating>
                <CustomInput
                  required
                  placeholder='Retype Password *'
                  type='password'
                  name='password2'
                  value={inputs.password2}
                  onChange={onInputsChange}
                />
                <Label>Retype Password *</Label>
              </FormGroup>
            )}
            <ImagePageText>{authType === 'Login' ? "Don't have an account? " : 'Have an account? '}</ImagePageText>
            <ImagePageButton onClick={_ => setAuthType(authType !== 'Login' ? 'Login' : 'Register')}>
              {authType !== 'Login' ? 'Login' : 'Register'}
            </ImagePageButton>

            <br />

            <ImagePageText>Forgot your password? </ImagePageText>
            <ImagePageButton onClick={_ => setAuthType('Reset Password')}>Reset password</ImagePageButton>

            <br />
            <br />
            <CustomButton type='submit' color='primary' id='save-button'>
              {authType}
            </CustomButton>
            <UncontrolledTooltip placement='top' target='save-button'>
              {authType}
            </UncontrolledTooltip>
          </Form>
        </ImagePageContent>
      </Section>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>One Time Passcode</ModalHeader>
        <ModalBody>
          <FormGroup floating>
            <CustomInput
              required
              placeholder='Email OTP *'
              type='number'
              name='code'
              value={inputs.code}
              onChange={onInputsChange}
            />
            <Label>Email OTP *</Label>
            <FormText>Your OTP (One Time Passcode) can only be used once.</FormText>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color='primary' onClick={onRegisterClick} className='w-100'>
            {authType}
          </Button>
        </ModalFooter>
      </Modal>
    </FullPage>
  );
};

const mapStateToProps = state => ({
  isAuthenticated: state.admin.isAuthenticated,
});

export default connect(mapStateToProps, {
  login,
  register,
  getAdmin,
  setAlert,
  putAdminPassword,
  confirmEmail,
})(AuthPage);
